import React, { useCallback, useMemo, useContext } from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { HistoryContext } from '../../utils/hooks/History'

import gsap from 'gsap'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import {
  addTrailingSlash,
  sourceScrollStorageKey,
} from '../../utils/helpers/utils'
import { AnimationStateContext } from '../../utils/hooks/AnimationState'

const LocalizedTransitionLink = React.forwardRef((props, ref) => {
  const { language: locale, defaultLanguage: defaultLang } =
    useContext(I18nextContext)
  const { saveScrollView } = useContext(HistoryContext)
  const { setIsReadyForAnimation } = useContext(AnimationStateContext)
  const {
    to,
    entry = null,
    exit = null,
    localized = true,
    addTrailingSlash: _addTrailingSlash = true,
    entryTransitionType = 'fadeIn',
    exitTransitionType = 'fadeOut',
    ...rest
  } = props

  const triggerReadyForAnimation = useCallback(
    () => setIsReadyForAnimation(true),
    [setIsReadyForAnimation]
  )

  const preventScrollJump = useCallback(
    (node) => {
      if (!node) {
        return
      }

      const scrollTop = window.scrollY
      node.style.overflow = `hidden`
      node.style.height = `100vh`

      node.scrollTo(0, scrollTop)
      window.scrollTo(0, 0)

      saveScrollView(scrollTop)
    },
    [saveScrollView]
  )

  const preventScrollJumpIn = useCallback((node) => {
    const scrollStorage = sessionStorage.getItem(sourceScrollStorageKey)
    const previousScroll =
      scrollStorage && scrollStorage.length > 0
        ? sessionStorage.getItem(sourceScrollStorageKey) * 1
        : 0
    if (previousScroll > 0) {
      sessionStorage.setItem(sourceScrollStorageKey, '0')
    }

    node.style.height = `100vh`
    node.style.overflow = `hidden`
    node.scrollTo(0, previousScroll)

    const tl = gsap.timeline()
    tl.to(
      node,
      {
        height: 'auto',
        overflow: 'auto',
        duration: 0,
        onComplete: () => {
          window.scrollTo(0, previousScroll)
        },
      },
      0.6
    )
    tl.play()
  }, [])

  const slideInTransition = useMemo(
    () => ({
      length: 0.6,
      delay: 0,
      zIndex: 0,
      xPercent: 100,
      name: 'slideIn',
      trigger: ({ entry, node }) => {
        if (!node) {
          return
        }

        setIsReadyForAnimation(false)

        const pageBack = node.querySelector('.page__back')
        const slideInTl = gsap.timeline({
          onComplete: () => {
            gsap.set(node, { pointerEvents: 'all' })
          },
        })

        const page = node.querySelector('.page')
        slideInTl.to(node, { pointerEvents: 'none' }, 0)
        slideInTl.to(
          node,
          { xPercent: 100, zIndex: 0, opacity: 0, duration: 0 },
          0
        )
        slideInTl.to(page, { opacity: 0, duration: 0 }, 0)

        slideInTl.to(
          node,
          {
            duration: 0,
            onComplete: () => {
              setIsReadyForAnimation(true)
            },
          },
          0.6
        )
        slideInTl.to(
          node,
          {
            duration: 0.5,
            xPercent: 0,
            ease: 'expo.out',
            clearProps: 'transform',
          },
          0.1
        )
        slideInTl.to(
          page,
          { duration: 0.6, opacity: 1, ease: 'expo.out' },
          0.25
        )
        pageBack &&
          slideInTl.to(pageBack, { pointerEvents: 'all', duration: 0 }, 0.6)
        slideInTl.play()
      },
    }),
    [setIsReadyForAnimation]
  )

  const slideOutTransition = useMemo(
    () => ({
      length: 0.9,
      zIndex: 2,
      trigger: ({ exit, node, e }) => {
        if (!node) {
          return
        }

        const scrollY = window.scrollY
        const page = node.querySelector('.page')
        const pageBack = node.querySelector('.page__back')
        const pageBody = node.querySelectorAll('.page__body .container')

        preventScrollJump(node)

        const exitTL = gsap.timeline()

        pageBack &&
          exitTL.set(pageBack, {
            pointerEvents: 'none ',
          })

        pageBody &&
          exitTL.to(pageBody, { opacity: 0, duration: 0.6, ease: 'expo.in' }, 0)
        exitTL.to(page, { duration: 0.5, opacity: 0, ease: 'expo.in' }, 0.1)
        exitTL.to(node, { duration: 0.6, xPercent: 100, ease: 'expo.in' }, 0.25)
        pageBack &&
          exitTL.to(
            pageBack,
            { position: 'absolute', top: scrollY, duration: 0, ease: 'none' },
            0
          )

        exitTL.play()
      },
    }),
    [preventScrollJump]
  )

  const fadeInTransition = useMemo(() => {
    return {
      length: 0.5,
      delay: 0.1,
      name: 'fadeIn',
      trigger: ({ entry, node }) => {
        if (!node) {
          return
        }
        const pageContent = node.querySelector('.page__content')
        const footer = node.querySelector('.footer')
        const contentToReveal = [pageContent, ...(footer ? [footer] : [])]

        gsap.set(contentToReveal, { opacity: 0 }, 0)
        gsap.set(node, { pointerEvents: 'none', duration: 0 }, 0)
        const fadeIn = gsap.timeline({
          onComplete: () => {
            sessionStorage.setItem(sourceScrollStorageKey, '0')
          },
        })
        fadeIn.to(
          contentToReveal,
          {
            opacity: 1,
            duration: 0.3,
            onComplete: () => {
              triggerReadyForAnimation()
            },
          },
          0
        )
        fadeIn.to(node, { pointerEvents: 'all', duration: 0 }, 0.6)
        fadeIn.play()
      },
    }
  }, [triggerReadyForAnimation])

  const fadeOutTransition = useMemo(() => {
    return {
      length: 0.3,
      name: 'fadeOut',
      trigger: ({ entry, node }) => {
        if (!node) {
          return
        }

        const headers = node.querySelectorAll('.header')
        const pageContent = node.querySelector('.page__content')
        const footer = node.querySelector('.footer')

        const contentToHide = [pageContent, ...(footer ? [footer] : [])]

        setIsReadyForAnimation(false)
        preventScrollJump(node)

        const fadeOutTl = gsap.timeline()
        fadeOutTl.to(node, { pointerEvents: 'none', duration: 0 }, 0)
        fadeOutTl.to(headers, { translateY: 0, duration: 0.2 }, 0)
        fadeOutTl.to(contentToHide, { opacity: 0, duration: 0.3 }, 0)
        fadeOutTl.play()
      },
    }
  }, [setIsReadyForAnimation, preventScrollJump])

  const entryNoTransition = useMemo(
    () => ({
      length: 0.6,
      zIndex: 1,
      trigger: ({ entry, node }) => {
        if (!node) {
          return
        }

        const pageBack = node.querySelector('.page__back')
        const entryTL = gsap.timeline()

        preventScrollJumpIn(node)
        pageBack &&
          entryTL.set(pageBack, {
            pointerEvents: 'none ',
          })
        pageBack &&
          entryTL.to(pageBack, { pointerEvents: 'all', duration: 0 }, 0.6)
        entryTL.play()
      },
    }),
    [preventScrollJumpIn]
  )

  const exitNoTransition = useMemo(
    () => ({
      name: 'exit-none',
      length: 0.6,
      trigger: ({ exit, node }) => {
        preventScrollJump(node)
        gsap.set(node, { opacity: 1, zIndex: 1 })
      },
    }),
    [preventScrollJump]
  )

  const entryTransition = useMemo(() => {
    if (entry) {
      return entry
    }
    switch (entryTransitionType) {
      case 'none':
        return entryNoTransition
      case 'slideIn':
        return slideInTransition
      default:
        return fadeInTransition
    }
  }, [
    entry,
    entryTransitionType,
    entryNoTransition,
    slideInTransition,
    fadeInTransition,
  ])

  const exitTransition = useMemo(() => {
    if (exit) {
      return exit
    }

    switch (exitTransitionType) {
      case 'none':
        return exitNoTransition
      case 'slideOut':
        return slideOutTransition
      default:
        return fadeOutTransition
    }
  }, [
    exit,
    exitTransitionType,
    slideOutTransition,
    fadeOutTransition,
    exitNoTransition,
  ])

  const localizedRoute = useMemo(() => {
    const route =
      locale === defaultLang ||
      to === `/${locale}` ||
      to.includes(`/${locale}/`)
        ? to
        : `/${locale}${to}`
    return _addTrailingSlash ? addTrailingSlash(route) : route
  }, [to, defaultLang, locale, _addTrailingSlash])

  if (!localized) {
    return (
      <TransitionLink
        ref={ref}
        entry={entryTransition}
        exit={exitTransition}
        to={to}
        {...rest}
      />
    )
  }

  return (
    <TransitionLink
      ref={ref}
      activeClassName="is-active"
      entry={entryTransition}
      exit={exitTransition}
      to={localizedRoute}
      {...rest}
    />
  )
})

export default LocalizedTransitionLink
