import React, { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const TenYearsBanner = forwardRef(function TenYearsBanner(props, ref) {

  const {t, i18n} = useTranslation(['common'])
  const getTenYearsSiteURL = useMemo(() => {
    return 'https://tenyears.source.paris/' + (i18n.language === 'en' ? 'en' : '')
  }, [i18n])

  return (
    <a href={getTenYearsSiteURL} rel="noopener noreferrer" target="_blank" className="ten-years-banner" ref={ref}>
      <div className="background">
        <picture>
          <source srcSet="/images/mobile-banner.png" media="(max-width: 768px)" />
          <source srcSet="/images/banner@2x.png" media="(min-width: 1440px)" />
          <source srcSet="/images/banner.png, /images/banner@2x.png 2x" />
          <img src="/images/banner.png" alt="Ten years banner background" />
        </picture>
      </div>
      <div className="content">
        <div className="title">{t('banner.title')}</div>
        <div className="button-icon">
          <div className="icon">
            <div className="circle"></div>
            <div className="arrow">
              <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.47 5v8.125a.469.469 0 0 1-.938 0V6.13l-9.2 9.2a.469.469 0 0 1-.663-.663l9.2-9.2H6.876a.469.469 0 1 1 0-.937H15a.469.469 0 0 1 .468.469Z"
                  fill="#fff" />
              </svg>
            </div>
          </div>
          <div className="legend">{t('banner.button')}</div>
        </div>
      </div>
    </a>
  )
})

export default TenYearsBanner