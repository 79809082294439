import React, { useMemo, createContext, useCallback, useState } from 'react'

export const RouteContext = createContext({
  setCurrentRoute: () => {}
})

export const RouteProvider = ({ children, name }) => {
  const [route, setRoute] = useState(name)

  const setCurrentRoute = useCallback((routeName) => {
    setRoute(routeName)
  }, [])

  const context = useMemo(() => ({
    route,
    setCurrentRoute
  }), [route, setCurrentRoute])

  return <RouteContext.Provider value={context}>{children}</RouteContext.Provider>
}

export const RouteConsumer = RouteContext.Consumer
