import React, { useContext, useMemo } from 'react'
import LocalizedTransitionLink from '../../LocalizedTransitionLink'
import { useTranslation } from 'react-i18next'
import { getServiceNavByLang } from '../../../utils/helpers/routes'
import { I18nextContext } from 'gatsby-plugin-react-i18next'

const ServiceHeader = () => {
  const { language } = useContext(I18nextContext)
  const { t } = useTranslation(['common'])
  const servicesNav = useMemo(() => getServiceNavByLang(language), [language])

  return (
    <nav className="services-nav container">
      <div className="row">
        <div className="col col-lg-12 col-xl-10 col-lg-offset-1 col-xl-offset-2">
          {servicesNav.map((page) => {
            return (
              <LocalizedTransitionLink
                to={page.slug}
                partiallyActive
                className="services-nav__item"
                key={page.id}
              >
                {t(`common:nav:${page.id}`)}
              </LocalizedTransitionLink>
            )
          })}
        </div>
      </div>
    </nav>
  )
}

export default ServiceHeader
