export const SHOW_NDA_PROJECTS = process.env.GATSBY_SHOW_NDA_PROJECTS === "true"
export const BASE_URL = process.env.GATSBY_BASE_URL || "https://source.paris";

export const notificationTimeout = 5000;
export const companyEmail = "hello@source.paris";
export const defaultLanguage = "fr";
export const langByContentLang = {
  'fr-fr': 'fr',
  'en-us': 'en'
}
export const supportedLanguages = {
  fr: {
    contentLang: 'fr-fr'
  },
  en: {
    contentLang: 'en-us'
  },
}

export const nbFeaturedProjectsPack = 3;
export const nbFeaturedProjectsOnDemand = 3;
