import gsap from 'gsap'
import { easeOutQuad } from '../../utils/constants/eases'
import { gsapParams } from '../../utils/page-animations/common'

const selectors = {
  header: '.header',
  items: '.header .nav__item',
  logo: '.header .header__top',
}

const show = () => {
  const tl = gsap.timeline().pause()
  tl.to(selectors.header, { position: 'absolute', translateY: 0, duration: 0 })
  tl.to(
    selectors.header,
    { ...gsapParams.fadeIn, duration: 0.3, pointerEvents: 'all' },
    0.1
  )

  return tl
}

const showFixed = (offset) => {
  const tl = gsap.timeline().pause()
  tl.to(
    selectors.header,
    { position: 'fixed', pointerEvents: 'all', duration: 0 },
    0
  )
  tl.to(
    selectors.header,
    { translateY: -offset, duration: 0.3, ease: easeOutQuad },
    0
  )
  tl.to(selectors.header, { ...gsapParams.fadeIn, duration: 0.3 }, 0.2)

  return tl
}

const hide = (offset) => {
  const tl = gsap.timeline().pause()
  tl.to(selectors.header, { position: 'fixed', duration: 0 })
  tl.to(selectors.header, {
    translateY: `-${offset}`,
    duration: 0.3,
    ease: easeOutQuad,
  })
  return tl
}

export { hide, show, showFixed }
