import React, { memo, useMemo } from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import LocalizedTransitionLink from '../LocalizedTransitionLink'

const SourceLogo = ({ light, asTransitionLink, className = '' }) => {
  const imgData = useMemo(() => {
    const colorLogo = light ? 'black' : 'white'
    return {
      src: `/images/logo-${colorLogo}.svg`,
      title: `Source logo ${colorLogo}`,
    }
  }, [light])

  const content = useMemo(
    () => (
      <div className={`f f-ai-center ${className}`}>
        <img
          src={imgData.src}
          alt="Logo source"
          width="109"
          height="14"
          title={imgData.title}
        />
      </div>
    ),
    [imgData, className]
  )

  return (
    <div className="f f-ai-center">
      {asTransitionLink ? (
        <LocalizedTransitionLink to="/" title="Source">
          {content}
        </LocalizedTransitionLink>
      ) : (
        <Link to="/">{content}</Link>
      )}
    </div>
  )
}

export default memo(SourceLogo)
