import '../../../utils/scripts/removeFocusOutlineMouseOnly'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

import '../../../styles/main.scss'
import { defaultLanguage } from '../../../utils/constants/global'
import generateSchemaOrg from '../../../utils/constants/schemaOrg'

const SEO = ({ seo = {}, language, internationalRoute, uri }) => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `)
  const { t } = useTranslation()
  const { title = null, description = '', contentType = 'website', image } = seo
  const { siteUrl } = site.siteMetadata
  const defaultImage = language === "fr" ? '/images/fr-og.jpg' : '/images/en-og.jpg';
  const shareImage = image || defaultImage

  const alternateLangs = useMemo(() => {
    if (!internationalRoute) return null
    return Object.entries(internationalRoute.langs).map(([key, path]) => {
      const prefix = defaultLanguage === key ? '' : `/${key}`
      const end = path !== "/" ? "/" : "";
      return (
        <link
          key={key}
          rel="alternate"
          hrefLang={key}
          href={`${siteUrl}${prefix}${path}${end}`}
        />
      )
    })
  }, [siteUrl, internationalRoute])

  const meta = {
    siteUrl,
    title: title || site.siteMetadata.title,
    type: contentType,
    description,
    url: `${siteUrl}${uri !== "/" ? uri+"/" : ""}`,
    image: `${siteUrl}${shareImage}`,
    twitterUsername: '@source_paris',
    language,
  }

  return (
    <Helmet>
      <html lang={meta.language} />
      <link rel="icon" href={'./favicon.ico'} />
      {alternateLangs}
      <link rel="canonical" href={meta.url} />
      <meta property="image" content={meta.image} />
      <meta name="robots" content="index, follow" />

      <meta name="description" content={meta.description} />

      <meta name="og:type" prefix="og: http://ogp.me/ns#" content={meta.type} />
      <meta
        property="og:title"
        prefix="og: http://ogp.me/ns#"
        content={meta.title}
      />
      <meta property="og:image" content={meta.image} />
      <meta property="og:description" content={meta.description} />
      <meta
        property="og:url"
        prefix="og: http://ogp.me/ns#"
        content={meta.url}
      />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={meta.twitterUsername} />
      <meta name="twitter:site" content={meta.twitterUsername} />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />
      <title>{meta.title}</title>
      <script type="application/ld+json">
        {JSON.stringify(generateSchemaOrg(t, meta))}
      </script>
    </Helmet>
  )
}

export default SEO
