import { companyEmail, defaultLanguage } from './global'
import { dribbbleUrl, instagramUrl, mediumUrl, twitterUrl } from './socialMedias'
import { getRoutesByLang, routeNames } from '../helpers/routes'

const generateSchemaOrg = (t, { siteUrl, language }) => {
  const localizedRoutes = getRoutesByLang(language);
  const prefix = defaultLanguage === language ? '' : `/${language}`;
  return [
    {
      "@context": "http://schema.org/",
      "@type": "Organization",
      "legalName": "Source",
      "url": siteUrl,
      "contactPoint": {
        "@type": "ContactPoint",
        "email": companyEmail,
        "contactType": "customer support"
      },
      "email": companyEmail,
      "logo": `${siteUrl}/images/logo-black.svg`,
      "sameAs": [
        dribbbleUrl,
        twitterUrl,
        instagramUrl,
        mediumUrl,
      ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "71 rue de la fraternité",
        "addressLocality": "MONTREUIL",
        "addressRegion": "Ile de france",
        "postalCode": "93100",
        "addressCountry": "FR"
      },
    },
    {
      "@context": "http://schema.org/",
      "@type": "WebSite",
      "name": "Source",
      "url": siteUrl,
      "potentialAction": {
        "@type": "SearchAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": `${siteUrl}${prefix}${localizedRoutes[routeNames.projects]}?s={search_term_string}`,
        },
        "query-input": "required name=search_term_string"
      }
    },
    {
      "@context":"http://schema.org",
      "@type":"ItemList",
      "itemListElement":[
        {
          "@type": "SiteNavigationElement",
          "position": 1,
          "name": t('common:nav:services'),
          "description": t('services:seo:description'),
          "url": `${siteUrl}${prefix}${localizedRoutes[routeNames.services]}`,
        },
        {
          "@type": "SiteNavigationElement",
          "position": 2,
          "name": t('common:nav:about'),
          "description": t('about:seo:description'),
          "url": `${siteUrl}${prefix}${localizedRoutes[routeNames.about]}`,
        },
        {
          "@type": "SiteNavigationElement",
          "position": 3,
          "name": t('common:nav:clients'),
          "description": t('clients:seo:description'),
          "url": `${siteUrl}${prefix}${localizedRoutes[routeNames.clients]}`,
        },
        {
          "@type": "Careers",
          "position": 4,
          "name": t('common:nav:careers'),
          "description": t('careers:seo:description'),
          "url": `${siteUrl}${prefix}${localizedRoutes[routeNames.careers]}`,
        },
        {
          "@type": "SiteNavigationElement",
          "position": 5,
          "name": t('common:nav:projects'),
          "description": t('projects:seo:description'),
          "url": `${siteUrl}${prefix}${localizedRoutes[routeNames.projects]}`,
        }
      ]
    }
  ]
}

export default generateSchemaOrg;

