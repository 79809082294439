import React, { memo } from 'react'
import {
  instagramUrl,
  mediumUrl,
  twitterUrl,
  linkedinUrl,
} from '../../utils/constants/socialMedias'
import {
  iconInstagram,
  iconMedium,
  iconTwitter,
  iconLinkedin,
} from '../../utils/icons'

const SocialMedias = ({ className }) => (
  <div className={`social-medias ${className ? className : ''}`}>
    <a
      href={linkedinUrl}
      className="social-medias__icon"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Linkedin"
    >
      {iconLinkedin}
    </a>
    <a
      href={twitterUrl}
      className="social-medias__icon"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Twitter"
    >
      {iconTwitter}
    </a>
    <a
      href={instagramUrl}
      className="social-medias__icon"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Instagram"
    >
      {iconInstagram}
    </a>
    <a
      href={mediumUrl}
      className="social-medias__icon"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Medium"
    >
      {iconMedium}
    </a>
  </div>
)

export default memo(SocialMedias)
