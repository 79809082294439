import gsap from 'gsap'
import SplitType from 'split-type'
import {
  easeOutQuad,
  easeOutCubic,
  easeInQuad,
  easeOutCirc,
} from '../../utils/constants/eases'
import { breakpoints } from '../helpers/utils'

const gsapParams = {
  fadeInUp: { opacity: 1, translateY: 0, duration: 0.6, ease: easeOutQuad },
  fadeIn: { opacity: 1, duration: 0.6, ease: easeInQuad },
  show: { opacity: 1 },
  showInUp: { opacity: 1, translateY: 0 },
  fadeOut: { opacity: 0 },
  fadeOutDown: { opacity: 0, translateY: 80 },
  fadeOutDown10: { opacity: 0, translateY: 10 },
  fadeOutDown20: { opacity: 0, translateY: 20 },
  fadeOutDown30: { opacity: 0, translateY: 30 },
  fadeOutDown40: { opacity: 0, translateY: 40 },
  fadeOutDown50: { opacity: 0, translateY: 50 },
  fadeOutDown60: { opacity: 0, translateY: 60 },
  fadeOutDown100: { opacity: 0, translateY: 100 },
  fadeOutDown20P: { opacity: 0, translateY: '20%' },
  fadeOutDown100P: { opacity: 0, translateY: '100%' },
}

const selectors = {
  contact: {
    wrapper: '.contact',
    title: '.contact__title',
    cta: '.contact__button',
    link: '.contact__link',
  },
  shuffle: {
    wrapper: '.shuffle',
    items: '.shuffle__item',
    cta: '.shuffle__cta',
  },
  modalHeader: {
    subtitle: '.modal-header__subtitle',
    title: '.modal-header__title',
  },
  modal: {
    title: '.page__title',
    titleLines: '.page__title-line',
    desc: '.page__desc',
    info: '.page__info',
  },
  pack: {
    mentions: '.page__mentions',
    list: {
      wrapper: '.list-template-pack',
      items: '.list-template-pack__item',
    },
  },
  clients: {
    wrapper: '.client-icons',
    items: '.client-icons .client-icon',
    title: '.client-icons__title',
  },
  advantages: {
    wrapper: '.tpl-advantages-list',
    title: '.tpl-advantages-list__title',
    items: '.advantage',
  },
  services: {
    wrapper: '.tpl-service__services',
    title: '.tpl-service__services__title',
    items: '.card-animated',
  },
}

const setContactEntry = (show) => {
  const contactComponent = document.querySelector(selectors.contact.wrapper)
  if (contactComponent) {
    gsap.set(
      selectors.contact.title,
      show ? { ...gsapParams.showInUp } : { opacity: 0, translateY: '5%' }
    )
    gsap.set(
      selectors.contact.cta,
      show ? { ...gsapParams.showInUp } : { opacity: 0, translateY: '10%' }
    )
    gsap.set(
      selectors.contact.link,
      show ? { ...gsapParams.showInUp } : { opacity: 0, translateY: '10%' }
    )
  }
}

const animateContactEntry = ({ start = 'top 80%', end = 'bottom 90%' }) => {
  const contactComponent = document.querySelector(selectors.contact.wrapper)

  if (contactComponent) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: selectors.contact.wrapper,
        start: start,
        end: end,
        once: true,
      },
    })

    tl.to(
      selectors.contact.title,
      { opacity: 1, duration: 0.3, ease: easeOutQuad },
      0
    )
    tl.to(
      selectors.contact.cta,
      { ...gsapParams.fadeInUp, ease: easeOutCubic },
      0.15
    )
    tl.to(
      selectors.contact.link,
      { ...gsapParams.fadeInUp, ease: easeOutCubic },
      0.15
    )
    return tl
  }
}

const setClientsEntry = (show) => {
  gsap.set(
    selectors.clients.title,
    show ? { ...gsapParams.showInUp } : { opacity: 0, translateY: '5%' }
  )
  gsap.set(
    selectors.clients.items,
    show ? { opacity: 0.95, translateY: '0' } : { opacity: 0, translateY: '5%' }
  )
}

const animateClientsEntry = () => {
  const isMobile = window.innerWidth < breakpoints.md

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.clients.wrapper,
      start: '10% 90%',
      end: 'bottom center',
      once: true,
    },
  })

  tl.to(
    selectors.clients.title,
    {
      opacity: 1,
      translateY: 0,
      delay: 0.5,
      duration: 0.6,
      ease: easeOutQuad,
    },
    0
  )
  tl.to(
    selectors.clients.items,
    {
      opacity: 0.95,
      translateY: 0,
      delay: 0.5,
      duration: 0.6,
      ease: easeOutQuad,
      stagger: {
        each: 0.05,
        grid: isMobile ? [3, 3] : [4, 2],
        from: 'start',
        axis: 'x',
      },
    },
    0
  )

  return tl
}

const setTitleEntry = (selectors, lineClass, show) => {
  new SplitType(selectors.title, {
    lineClass: lineClass,
    types: 'lines',
  })

  const title = document.querySelector(selectors.title)
  const lines = document.querySelectorAll(selectors.line)
  const alias = document.querySelector(selectors.alias)

  title && gsap.set(title, { ...(show ? gsapParams.show : gsapParams.fadeOut) })
  alias &&
    gsap.set(alias, { ...(show ? gsapParams.show : gsapParams.fadeOutDown30) })

  if (lines) {
    gsap.set(lines, { ...(show ? gsapParams.show : gsapParams.fadeOutDown50) })
  }
}

const animateTitleEntry = (selectors, onComplete) => {
  const title = document.querySelector(selectors.title)
  const lines = document.querySelectorAll(selectors.line)
  const alias = document.querySelector(selectors.alias)

  const tl = gsap.timeline({
    onComplete: onComplete ? onComplete : () => {},
  })
  title && tl.to(title, { opacity: 1, duration: 0 }, alias ? 0.15 : 0)
  lines &&
    tl.to(lines, { ...gsapParams.fadeInUp, stagger: 0.05, duration: 0.7 })
  alias && tl.to(alias, { ...gsapParams.fadeInUp, duration: 0.7 })

  return tl
}

const setModalHeaderContent = (show) => {
  const title = document.querySelector(selectors.modalHeader.title)
  const subtitle = document.querySelector(selectors.modalHeader.subtitle)

  title && gsap.set(title, show ? gsapParams.show : gsapParams.fadeOut)
  subtitle && gsap.set(subtitle, show ? gsapParams.show : gsapParams.fadeOut)
}

const animateModalHeaderContent = (wrapper) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: wrapper,
      start: '-50% center',
      end: 'bottom center',
      once: true,
    },
  })

  const title = document.querySelector(selectors.modalHeader.title)
  const subtitle = document.querySelector(selectors.modalHeader.subtitle)
  subtitle && tl.to(subtitle, { ...gsapParams.fadeIn }, 0.1)
  title && tl.to(title, { ...gsapParams.fadeIn }, 0.15)

  return tl
}

const setShuffleEntry = (hideInMobile, show) => {
  const cta = document.querySelector(selectors.shuffle.cta)

  const isMobile = window.innerWidth < breakpoints.md
  const shuffleItems = document.querySelectorAll(selectors.shuffle.items)

  gsap.set(selectors.shuffle.items, show ? gsapParams.show : gsapParams.fadeOut)

  if (hideInMobile && isMobile && shuffleItems && shuffleItems.length > 0) {
    gsap.set(shuffleItems[0], show ? gsapParams.showInUp : { translateY: 100 })
  } else {
    gsap.set(
      selectors.shuffle.items,
      show ? gsapParams.fadeInUp : { opacity: 0, translateY: 100 }
    )
  }

  cta && gsap.set(cta, show ? gsapParams.show : gsapParams.fadeOut)
}

const setShuffleVisible = () => {
  gsap.set(selectors.shuffle.items, { ...gsapParams.fadeInUp })
}

const animateShuffleEntry = (wrapper, hideInMobile) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: wrapper,
      start: 'top 80%',
      end: 'bottom 90%',
      once: true,
    },
  })

  const cta = document.querySelector(selectors.shuffle.cta)
  const isMobile = window.innerWidth < breakpoints.md
  const shuffleItems = document.querySelectorAll(selectors.shuffle.items)

  if (isMobile && hideInMobile) {
    if (shuffleItems && shuffleItems.length > 0) {
      tl.to(
        shuffleItems[0],
        {
          opacity: 1,
          translateY: 0,
          stagger: 0.05,
          duration: 0.8,
          ease: easeOutCirc,
        },
        0
      )
    }
  } else {
    tl.to(
      selectors.shuffle.items,
      {
        opacity: 1,
        translateY: 0,
        stagger: 0.05,
        duration: 0.8,
        ease: easeOutCirc,
      },
      0
    )
  }

  cta && tl.to(cta, { opacity: 1, duration: 0.3, ease: easeOutQuad }, 0.3)
  return tl
}

const setModalEntry = (params, show) => {
  const title = new SplitType(selectors.modal.title, {
    lineClass: 'page__title-line',
    types: 'lines',
  })

  const descFadeOut =
    params && params.onlyFadeInDesc
      ? gsapParams.fadeOut
      : gsapParams.fadeOutDown40
  gsap.set(
    selectors.modal.titleLines,
    show ? gsapParams.showInUp : gsapParams.fadeOutDown100P
  )
  gsap.set(selectors.modal.desc, show ? gsapParams.showInUp : descFadeOut)
  document.querySelector(selectors.modal.info) &&
    gsap.set(selectors.modal.info, show ? gsapParams.show : gsapParams.fadeOut)

  return title
}

const animateModalEntry = (wrapper, params) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: wrapper,
      start: '-50% center',
      end: 'bottom center',
      once: true,
    },
  })
  tl.to(
    selectors.modal.titleLines,
    { ...gsapParams.fadeInUp, stagger: 0.05, duration: 0.7 },
    0.15
  )
  tl.to(
    selectors.modal.desc,
    {
      ...(params && params.onlyFadeInDesc
        ? gsapParams.fadeIn
        : gsapParams.fadeInUp),
      stagger: 0.05,
    },
    0.6
  )
  document.querySelector(selectors.modal.info) &&
    tl.to(selectors.modal.info, { ...gsapParams.fadeIn }, 0.6)

  return tl
}

const setPackListEntry = () => {
  gsap.set(selectors.pack.list.items, { ...gsapParams.fadeOut })

  const mentions = document.querySelector(selectors.pack.mentions)
  mentions && gsap.set(mentions, { ...gsapParams.fadeOut })
}

const animatePackListEntry = (wrapper) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: wrapper,
      start: '-50% center',
      end: 'bottom center',
      once: true,
    },
  })

  const mentions = document.querySelector(selectors.pack.mentions)

  tl.to(selectors.pack.list.items, { ...gsapParams.fadeIn, stagger: 0.05 }, 0.7)
  mentions && tl.to(mentions, { ...gsapParams.fadeIn }, 0.7)

  return tl
}

const setAdvantagesListEntry = (show) => {
  const advantagesComponent = document.querySelector(
    selectors.advantages.wrapper
  )

  if (advantagesComponent) {
    const isMobile = window.innerWidth < breakpoints.md

    const fadeOutParam = isMobile
      ? gsapParams.fadeOutDown30
      : gsapParams.fadeOutDown50
    gsap.set(
      selectors.advantages.title,
      show ? gsapParams.showInUp : fadeOutParam
    )
    gsap.set(
      selectors.advantages.items,
      show ? gsapParams.showInUp : fadeOutParam
    )
  }
}

const animateAdvantagesList = (isMobile) => {
  const advantagesComponent = document.querySelector(
    selectors.advantages.wrapper
  )

  if (advantagesComponent) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: selectors.advantages.wrapper,
        start: '10% 90%',
        end: 'bottom center',
        once: true,
      },
    })
    tl.to(
      selectors.advantages.title,
      {
        opacity: 1,
        translateY: 0,
        duration: 0.6,
        ease: easeOutQuad,
      },
      0
    )
    tl.to(
      selectors.advantages.items,
      {
        opacity: 1,
        translateY: 0,
        duration: 0.6,
        ease: easeOutQuad,
        stagger: {
          each: 0.025,
          grid: isMobile ? [1, 1] : [2, 2],
          from: 'start',
          axis: isMobile ? 'y' : 'x',
        },
      },
      0
    )
    return tl
  }
}

const setServicesEntry = (show) => {
  const isMobile = window.innerWidth < breakpoints.md

  const fadeOutParam = isMobile
    ? gsapParams.fadeOutDown30
    : gsapParams.fadeOutDown50
  gsap.set(selectors.services.title, show ? gsapParams.showInUp : fadeOutParam)
  gsap.set(selectors.services.items, show ? gsapParams.showInUp : fadeOutParam)
}

const animateServices = (isMobile) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.services.wrapper,
      start: '10% 90%',
      end: 'bottom center',
      once: true,
    },
  })
  tl.to(
    selectors.services.title,
    {
      opacity: 1,
      translateY: 0,
      duration: 0.6,
      ease: easeOutQuad,
    },
    0
  )
  tl.to(
    selectors.services.items,
    {
      opacity: 1,
      translateY: 0,
      duration: 0.6,
      ease: easeOutQuad,
      stagger: {
        each: 0.025,
        grid: isMobile ? [1, 1] : [2, 3],
        from: 'start',
        axis: isMobile ? 'y' : 'x',
      },
    },
    0
  )

  return tl
}

export {
  animateTitleEntry,
  setContactEntry,
  animateContactEntry,
  setModalHeaderContent,
  animateModalHeaderContent,
  setShuffleEntry,
  animateShuffleEntry,
  setModalEntry,
  animateModalEntry,
  setPackListEntry,
  animatePackListEntry,
  setTitleEntry,
  setShuffleVisible,
  setClientsEntry,
  animateClientsEntry,
  setAdvantagesListEntry,
  animateAdvantagesList,
  setServicesEntry,
  animateServices,
  gsapParams,
}
