import { supportedLanguages } from '../constants/global'

const routeNames = {
  home: 'home',
  services: 'services',
  clients: 'clients',
  about: 'about',
  careers: 'careers',
  projects: 'projects',
  project: 'project',
  lockedProjects: 'locked-projects',
  packages: 'packages',
  pack: 'pack',
  industry: 'industry',
  service: 'service',
  strategy: 'strategy',
  product: 'product',
  atScale: 'at-scale',
  tech: 'tech',
}

const internationalRoutes = {
  [routeNames.home]: {
    id: routeNames.home,
    template: '_index.js',
    langs: {
      en: '/',
      fr: '/',
    },
  },
  [routeNames.services]: {
    id: routeNames.services,
    template: '_services.js',
    langs: {
      en: '/service/product',
      fr: '/service/produit',
    },
  },
  [routeNames.about]: {
    id: routeNames.about,
    template: '_about.js',
    langs: {
      en: '/about',
      fr: '/a-propos',
    },
  },
  [routeNames.clients]: {
    id: routeNames.clients,
    template: '_clients.js',
    langs: {
      en: '/clients',
      fr: '/clients',
    },
  },
  [routeNames.careers]: {
    id: routeNames.careers,
    template: '_careers.js',
    langs: {
      en: '/careers',
      fr: '/recrutement',
    },
  },
  [routeNames.projects]: {
    id: routeNames.projects,
    template: '_projects.js',
    langs: {
      en: '/projects',
      fr: '/projets',
    },
  },
  [routeNames.lockedProjects]: {
    id: routeNames.lockedProjects,
    template: '_projects.js',
    langs: {
      en: '/locked-projects',
      fr: '/projets-lockes',
    },
  },
  [routeNames.packages]: {
    id: routeNames.packages,
    template: '_packages.js',
    langs: {
      en: '/packages',
      fr: '/packages',
    },
  },
  [routeNames.industry]: {
    id: routeNames.industry,
    template: null,
    langs: {
      en: '/industries',
      fr: '/industries',
    },
  },
  [routeNames.service]: {
    id: routeNames.service,
    template: null,
    langs: {
      en: '/service',
      fr: '/service',
    },
  },
  [routeNames.strategy]: {
    id: routeNames.strategy,
    template: null,
    langs: {
      en: '/service/strategy',
      fr: '/service/strategie',
    },
  },
  [routeNames.product]: {
    id: routeNames.product,
    template: null,
    langs: {
      en: '/service/product',
      fr: '/service/produit',
    },
  },
  [routeNames.atScale]: {
    id: routeNames.atScale,
    template: null,
    langs: {
      en: '/service/design-at-scale',
      fr: '/service/design-at-scale',
    },
  },
  [routeNames.tech]: {
    id: routeNames.tech,
    template: null,
    langs: {
      en: '/service/tech',
      fr: '/service/tech',
    },
  },
}

const navLinks = [
  internationalRoutes[routeNames.services],
  internationalRoutes[routeNames.about],
  internationalRoutes[routeNames.clients],
  internationalRoutes[routeNames.careers],
  internationalRoutes[routeNames.projects],
]

const servicesNavLinks = [
  internationalRoutes[routeNames.strategy],
  internationalRoutes[routeNames.product],
  internationalRoutes[routeNames.atScale],
  internationalRoutes[routeNames.tech],
]

const navByLang = Object.keys(supportedLanguages).reduce((acc, langKey) => {
  acc[langKey] = navLinks.reduce((accNav, internationalRoute) => {
    const slug = internationalRoute.langs[langKey]
    if (slug) {
      accNav.push({ id: internationalRoute.id, slug })
    }
    return accNav
  }, [])
  return acc
}, {})

const servicesNavByLang = Object.keys(supportedLanguages).reduce(
  (acc, langKey) => {
    acc[langKey] = servicesNavLinks.reduce((accNav, internationalRoute) => {
      const slug = internationalRoute.langs[langKey]
      if (slug) {
        accNav.push({ id: internationalRoute.id, slug })
      }
      return accNav
    }, [])
    return acc
  },
  {}
)

const getNavByLang = (lang) => navByLang[lang]
const getServiceNavByLang = (lang) => servicesNavByLang[lang]
const getRoutesByLang = (lang) =>
  Object.keys(internationalRoutes).reduce((acc, internationalRouteKey) => {
    acc[internationalRouteKey] =
      internationalRoutes[internationalRouteKey].langs[lang]
    return acc
  }, {})

export {
  routeNames,
  internationalRoutes,
  getNavByLang,
  getRoutesByLang,
  getServiceNavByLang,
}
